<template>
	<div>
		<div class="relativepb-24 bg-gradient-to-tr from-color-1 to-color-2">
			<div class="min-h-screen">
				<div class="flex items-center justify-center">
					<div class="flex flex-col mt-24 container mx-auto items-center justify-center">
						<label for="avp" class="text-white font-semibold"
							>Average Punk
							<input type="radio" id="avp" name="punk_type" v-bind:value="true" v-model="isAVP" />
						</label>
						<label for="mvp" class="text-white font-semibold"
							>Metaverse Punk
							<input type="radio" id="mvp" name="punk_type" v-bind:value="false" v-model="isAVP" />
						</label>
						<p v-if="errorMessage === ''" class="text-white font-semibold">PUNK ID {{ punkId }}</p>
						<p v-else class="text-white font-semibold">
							{{ errorMessage }}
						</p>
						<div>
							<input
								v-model="punkId"
								class="border py-2 px-3 text-grey-900 rounded-tl rounded-bl outline-none"
								type="punk"
								name="punk"
								placeholder="ID"
							/>
							<button
								:disabled="!punkId || errorMessage !== ''"
								class="bg-purple-600 text-white py-2 px-3 rounded-tr rounded-br border border-purple-600"
								@click="getPunk"
							>
								Search
							</button>
						</div>
					</div>
				</div>
				<div>
					<div v-if="fetching" class="max-w-sm flex flex-col items-center justify-center mt-12 mx-auto">
						<h2 class="text-white font-semibold">One moment</h2>
					</div>
					<div v-if="punk" class="max-w-sm flex flex-col items-center justify-center mt-12 mx-auto">
						<img class="object-cover object-center rounded" :src="punkImage" alt="avatar" />
						<button class="text-white font-semibold bg-purple-600 py-2 px-3 rounded mt-3 mx-2" @click="goToOS">VIEW ON OPEN SEA</button>
						<div v-if="isOwner || owner === metamaskAddress.toLowerCase()" class="flex">
							<div class="flex">
								<button class="text-white font-semibold bg-purple-600 py-2 px-3 rounded mt-3 mx-2" @click="download">
									DOWNLOAD FULL RES
								</button>
								<button @click="DL_PFP" class="text-white font-semibold bg-purple-600 py-2 px-3 rounded mt-3 mx-2">DOWNLOAD PFP</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<canvas id="averageCanvas" width="0" height="0" style="display: none"></canvas>
	</div>
</template>

<script>
import { ADDRESS, ABI, MVP_ADDRESS } from '../../util/constants/contract.js';
import Web3 from 'web3';
import { mapGetters } from 'vuex';

export default {
	name: 'Punk',
	components: {},
	async mounted() {
		const { ethereum } = window;
		if (!ethereum) {
			return;
		}
		window.web3 = new Web3(ethereum);
		const web3 = window.web3;
		this.web3 = web3;
		this.contract = new web3.eth.Contract(ABI, ADDRESS);
	},
	data() {
		return {
			punkId: '',
			errorMessage: '',
			punk: null,
			fetching: false,
			contract: null,
			web3: null,
			isOwner: false,
			owner: '',
			format: {
				height: 380,
				width: 420,
			},
			isAVP: true,
		};
	},
	methods: {
		goToOS() {
			if (this.isAVP) {
				return window.open(`https://opensea.io/assets/0x6d5c70cc8dadfe63759b339a9deeb37b4fedf6cb/${this.punkId}`, '_blank');
			} else {
				return window.open(`https://opensea.io/assets/0xb0b1b5cfe1bc0ece15deb880fa457e89616d061b/${this.punkId}`, '_blank');
			}
		},
		DL_PFP() {
			let c = document.getElementById('averageCanvas');
			let ctx = c.getContext('2d');
			ctx.canvas.width = this.format.height;
			ctx.canvas.height = this.format.width;
			this.vcanvas = ctx;
			let imageObj = new Image();
			imageObj.crossOrigin = 'anonymous';
			imageObj.src = this.punkImage;
			const drawOnCanvas = new Promise((resolve) => {
				imageObj.onload = function () {
					// draw cropped image
					ctx.drawImage(
						imageObj,
						600,
						0, // Start at 70/20 pixels from the left and the top of the image (crop),
						750,
						750, // "Get" a `50 * 50` (w * h) area from the source image (crop),
						0,
						0, // Place the result at 0, 0 in the canvas,
						380,
						420
					);
					resolve();
				};
			});
			drawOnCanvas.then(() => {
				let newPfp = c.toDataURL('image/png');
				const link = document.createElement('a');
				link.href = newPfp;
				link.download = this.punk.name + '_pfp.png';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
		},
		async download() {
			const image = await fetch(this.punkImage);
			const imageBlog = await image.blob();
			const imageURL = URL.createObjectURL(imageBlog);
			const link = document.createElement('a');
			link.href = imageURL;
			link.download = this.punk.name;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		async getPunk() {
			try {
				this.fetching = true;
				this.punk = null;

				let response;
				if (this.isAVP) {
					response = await fetch(`https://avps.io/.netlify/functions/avgp/${this.punkId}`);
				} else {
					response = await fetch(`https://avps.io/.netlify/functions/mvp/${this.punkId}`);

					this.contract = new this.web3.eth.Contract(ABI, MVP_ADDRESS);
				}

				if (!response.ok && response.status == 404) {
					return (this.errorMessage = "This punk hasn't entered the metaverse yet");
				}

				const owner = await this.contract.methods.ownerOf(this.punkId).call();
				this.isOwner = owner.toLowerCase() === this.metamaskAddress.toLowerCase();
				this.owner = owner.toLowerCase();
				const data = await response.json();
				this.punk = data;
				this.fetching = false;
			} catch (error) {
				this.fetching = false;
				console.log(typeof error);
				console.log('Error: ' + error);
			}
		},
	},
	computed: {
		punkImage() {
			return 'https://ipfs.io/ipfs/' + this.punk.image.split('//')[1];
		},
		...mapGetters({
			metamaskAddress: 'connection/metamaskAddress',
		}),
	},
	watch: {
		punkId(val) {
			if (val !== '' && (isNaN(val) || val < 1 || val > 6666)) this.errorMessage = 'Invalid punk id';
			else {
				this.errorMessage = '';
			}
		},
	},
};
</script>
